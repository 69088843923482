html, body {
  height: 100%;
  margin: 0;
}

.dashboard-wrapper {
  display: flex;
  min-height: 100vh; /* заменили height: 100vh → min-height */
}

.sidebar {
  width: 250px;
  background-color: #1e1e2f;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* тянет футер вниз */
  padding: 20px;
}

.sidebar-title {
  font-size: 20px;
  margin-bottom: 20px;
}

.tab-menu {
  flex: 1;
}

.tab-item {
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 5px;
}

.tab-item:hover,
.tab-item.active {
  background-color: #34344a;
}

.sidebar-footer {
  border-top: 1px solid #444;
  padding-top: 15px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center; /* центрирует содержимое */
}

.tab-content {
  flex: 1;
  padding: 30px;
}

.help-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #1e1e2f;
  color: white;
  font-size: 24px;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0,0,0,0.3);
  z-index: 1000;
}

.help-button:hover {
  background-color: #34344a;
}

.logout-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.2s ease;
  align-self: center;
}

.logout-button:hover {
  background-color: #d9363e;
}
