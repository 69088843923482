.token-tab {
  padding: 20px;
}

.token-tab h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.token-display {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 12px 16px;
  font-family: monospace;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 20px;
}

.copy-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.token-buttons {
  display: flex;
  gap: 16px;
}

.token-buttons button {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #ccc;
  padding: 10px 16px;
  background: white;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
}

.token-buttons button:hover {
  background-color: #f0f0f0;
}
