.tunnels-tab {
  padding: 20px;
}

.tunnels-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 10px;
}

.tunnels-header h2 {
  font-size: 24px;
  margin: 0;
}

.tunnels-header p {
  margin: 4px 0 0;
  color: #666;
}

.tunnels-actions {
  display: flex;
  gap: 10px;
}

.doc-btn,
.create-btn {
  display: flex;
  align-items: center;
  gap: 6px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 14px;
  font-weight: 500;
}

.doc-btn {
  background: white;
  border: 1px solid #ddd;
}

.create-btn {
  background: #1e1e2f;
  color: white;
}

.tunnels-empty {
  background: #f9f9f9;
  padding: 40px;
  border-radius: 16px;
  text-align: center;
  color: #444;
  font-size: 16px;
}
