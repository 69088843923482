.login-page {
    display: flex;
    height: 100vh;
    font-family: 'Segoe UI', sans-serif;
    background: linear-gradient(to right, #f4f4f4, #e9ecef);
  }
  
  .login-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    background-color: #ffffff;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.05);
  }
  
  .login-title {
    font-size: 40px;
    margin-bottom: 10px;
    color: #2d2d2d;
  }
  
  .login-sub {
    margin-bottom: 25px;
    color: #666;
    font-size: 16px;
  }
  
  .login-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px;
    background-color: #f8f9fa;
    color: #333;
  }
  
  .login-right h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .login-right ul {
    list-style: none;
    padding: 0;
    font-size: 18px;
    line-height: 1.8;
  }
  
  .login-right li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  