.install-tab {
  padding: 20px;
}

.platform-tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.platform-tabs button {
  background: #f2f2f2;
  border: 1px solid #ccc;
  padding: 6px 12px;
  border-radius: 6px;
  cursor: pointer;
}

.platform-tabs .active {
  background-color: #1e1e2f;
  color: white;
}

.install-box {
  background: #f9f9f9;
  border-left: 4px solid #1e1e2f;
  padding: 15px;
  border-radius: 8px;
}

.install-box pre {
  background: #eee;
  padding: 10px;
  overflow-x: auto;
}

.step {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .step-number {
    min-width: 30px;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    font-weight: bold;
    font-size: 16px;
  }
  
  .step-content h2 {
    margin-top: 0;
  }
  
  pre {
    background: #f5f5f5;
    padding: 10px;
    border-radius: 6px;
    overflow-x: auto;
  }
  
  .platform-tabs {
    display: flex;
    gap: 10px;
    margin: 10px 0;
    flex-wrap: wrap;
  }
  
  .platform-tabs button {
    background: #f2f2f2;
    border: 1px solid #ccc;
    padding: 6px 12px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
  }
  
  .platform-tabs button.active {
    background-color: #1e1e2f;
    color: white;
    border: none;
  }
  